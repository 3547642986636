<template>
  <div class="wrapper" @click="onClick">
    <el-form
      label-position="right"
      ref="form"
      :rules="formRules"
      :model="form"
      label-width="110px"
      style="margin-bottom: 8px"
    >
      <ContentBlock title="员工基本信息">
        <el-row :gutter="20">
          <el-col :span="12" style="height: 51px">
            <el-form-item label="姓名：" prop="name">
              <el-input
                placeholder="请输入姓名"
                v-model.trim="form.name"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="手机号：" prop="phoneNumber">
              <span slot="label">
                手机号：
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="温馨提示：修改手机号后，登录账号会同时进行修改，如有微信绑定则需要重新进行绑定"
                  placement="top-start"
                >
                  <i class="el-icon-warning" />
                </el-tooltip>
              </span>
              <el-input
                placeholder="请输入手机号"
                v-model.trim="form.phoneNumber"
                maxlength="11"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="邮箱地址：" prop="email">
              <el-input
                placeholder="请输入邮箱地址"
                v-model.trim="form.email"
                clearable
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="工号：" prop="jobNumber">
              <el-input
                placeholder="请输入员工工号"
                v-model.trim="form.jobNumber"
                clearable
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="height: 51px">
            <el-form-item label="角色权限：" prop="roleIdList" class="roleItem">
              <el-radio-group v-model="form.adminFlag" @change="handleRoleChange" style="margin-right: 10px">
                <el-radio :label="true">超管
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="超级管理员：拥有本公司所有权限"
                    placement="top-start"
                  >
                    <i class="el-icon-question" />
                  </el-tooltip>
                </el-radio>
                <el-radio :label="false">非超管</el-radio>
              </el-radio-group>
              <el-select v-model="form.roleIdList" placeholder="请选择角色权限" multiple :disabled="form.adminFlag">
                <el-option v-for="item in roleLists" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="直属部门：" prop="departmentId">
              <DepartMentSelector
                :isShow="true"
                v-model.trim="form.departmentId"
                :isAllowLeaf="true"
                placeholder="请选择直属部门"
                :name.sync="form.departmentName"
                @change="handleParentChanged"
              ></DepartMentSelector>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="权限部门：" prop="authorityDepartmentIdList">
              <DepartMentSelectorChecked
                v-model.trim="form.authorityDepartmentIdList"
                :isAllowLeaf="true"
                placeholder="请选择权限部门"
                @change="authDeptChange"
              ></DepartMentSelectorChecked>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="员工状态：" prop="status">
              <el-select v-model="form.status" :disabled="!id" placeholder="请选择员工状态">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="height: 51px">
            <el-form-item label="标签：">
              <el-button
                data-index="button"
                style="margin: 0 6px 6px 0"
                type="primary"
                size="mini"
                @click="openTagSelector"
              >
                <i data-index="icon" class="el-icon-plus"></i>
                <span data-index="title">添加标签</span>
              </el-button>
              <el-tag
                size="small"
                closable
                style="margin: 0 6px 6px 0"
                v-for="(tag, index) in form.labelList || []"
                :key="tag.id"
                @close="handleClose(index)"
              >
                {{ tag.name }}
              </el-tag>
              <el-select
                v-show="visible"
                ref="tagSelectorPopover"
                v-model="form.labelList"
                value-key="labelId"
                multiple
                filterable
                remote
                placeholder="请选择"
                :multiple-limit="3"
                :remote-method="queryTags"
                :loading="loading"
                @focus="handleBlur"
              >
                <el-option
                  v-for="item in labelOptions"
                  :key="item.labelId"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </ContentBlock>
      <ContentBlock style="margin-top: 0" title="员工账号" v-if="!id">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账号名称：" prop="phoneNumber">
              <el-input placeholder="请输入账号名称" v-model.trim="form.phoneNumber" disabled clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号状态：" prop="accountStatus">
              <el-radio-group v-model="form.accountStatus" disabled>
                <el-radio label="DISABLE">禁用</el-radio>
                <el-radio label="ACTIVE">激活</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </ContentBlock>
    </el-form>
    <content-block title="设备服务能力">
      <el-button style="margin-bottom: 8px" type="primary" @click="insertEvent">新增</el-button>
      <el-form :model="deviceServiceForm" ref="deviceServiceForm">
        <el-table :data="deviceServiceForm.deviceServiceList" border style="width: 100%" max-height="500" size="small">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="deviceClassificationName"
            label="设备分类"
          ></el-table-column>
          <el-table-column align="center" prop="deviceName" label="设备名称" min-width="230">
            <template slot-scope="scope">
              <el-form-item
                :prop="`deviceServiceList.${scope.$index}.deviceName`"
                :rules="[{ required: true, message: '设备名称必填' }]"
              >
                <table-selector
                  :disabled="scope.row.businessScenarioCountList.some(v => v.count > 0)"
                  :device="scope.row.defaultDevice"
                  :name.sync="scope.row.deviceName"
                  :placement="'top-start'"
                  :disabledIds="disabledIds"
                  @change="v => handleChange(v, scope.row)"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" align="center" prop="spec" label="规格型号"></el-table-column>
          <el-table-column :show-overflow-tooltip="true" align="center" prop="brandName" label="品牌"></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            align="center"
            prop="enterpriseName"
            label="生产商"
          ></el-table-column>
          <el-table-column align="center" prop="businessScenarioList" label="业务场景及业务数量" min-width="360">
            <template slot-scope="scope">
              <el-form-item :prop="`deviceServiceList.${scope.$index}.businessScenarioList`">
                <el-checkbox-group
                  v-model="scope.row.businessScenarioList"
                  size="mini"
                  @change="v => handleCheckboxChange(v, scope.row.businessScenarioCountList)"
                >
                  <el-checkbox
                    v-for="tag in scope.row.businessScenarioCountList"
                    :key="tag.businessScenario"
                    :label="tag.businessScenario"
                    :disabled="!!tag.count"
                    border
                  >
                    {{ `${BusinessScenarioEnum[tag.businessScenario]}${tag.count === null ? ' --' : tag.count}` }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="dataSourceName" label="数据来源" width="100">
            <template slot-scope="scope">
              <span :style="{ color: scope.row.dataSource === 'ADD_MANUALLY' ? '#69c03b' : '#3fa1fd' }">
                {{ scope.row.dataSourceName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                v-if="!id || !scope.row.businessScenarioCountList.some(v => v.count > 0)"
                type="text"
                size="small"
                @click="removeSelectEvent(scope)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </content-block>
    <bottom-panel>
      <template>
        <el-button style="margin-left: 1em" type="danger" @click="back">返回</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </template>
    </bottom-panel>
  </div>
</template>
<script>
import SystemSetting from '@/api/systemSetting';
import DepartMentSelector from '@/components/departMentSelector';
import DepartMentSelectorChecked from '@/components/departMentSelectorChecked';
import TableSelector from '@/components/tableSelector.vue';
import BusinessScenarioEnum from '@/enums/BusinessScenarioEnum';

import _ from 'lodash';

export default {
  name: 'EDIT_EMPLOYEE',
  props: {
    id: {
      type: String,
    },
  },
  components: {
    DepartMentSelector,
    DepartMentSelectorChecked,
    TableSelector,
  },
  computed: {
    formRules () {
      return {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^1\d{10}$/,
            message: '手机号号码格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
        departmentId: [
          {
            required: true,
            message: '请选择直属部门',
            trigger: ['blur', 'change'],
          },
        ],
        status: [
          {
            required: true,
            message: '请选择员工状态',
            trigger: ['blur', 'change'],
          },
        ],
        roleIdList: [
          {
            required: !this.form.adminFlag,
            message: '请选择角色权限',
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            type: 'email',
            message: '邮箱格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
      };
    },
  },
  data () {
    return {
      BusinessScenarioEnum,
      businessTags: [],
      deviceServiceForm: {
        deviceServiceList: [],
      },
      loading: false,
      visible: false,
      form: {
        adminFlag: false,
        departmentId: '',
        authorityDepartmentIdList: [],
        email: '',
        labelList: [],
        name: '',
        phoneNumber: '',
        jobNumber: '',
        status: 'ON_JOB',
        roleIdList: [],
        departmentName: '',
        accountStatus: 'ACTIVE',
        accountStatusName: '激活',
        id: '',
      },
      labelOptions: [],
      roleLists: [],
      statusOptions: [
        {
          label: '在职',
          value: 'ON_JOB',
        },
        {
          label: '离职',
          value: 'LEAVE_OFFICE',
        },
      ],
      disabledIds: [],
      from: '',
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from.fullPath;
    });
  },
  methods: {
    onClick (e) {
      const index = e.target.getAttribute('data-index');

      this.visible = !!index;
    },
    handleBlur () {
      this.$refs.tagSelectorPopover.$refs.input.blur = () => {
        this.visible = false;
      };
    },
    back (refresh = false) {
      this.$tabs.close({
        to: this.from,
        refresh,
      });
    },
    handleCheckboxChange (codes, list) {
      list.forEach(v => {
        if (!v.count) {
          codes.includes(v.businessScenario) ? (v.count = 0) : (v.count = null);
        }
      });
    },
    handleChange (v, row) {
      row.brandId = v.brandId;
      row.brandName = v.brandName;
      row.deviceClassificationId = v.categoryId;
      row.deviceClassificationName = v.categoryFourth;
      row.deviceId = v.id;
      row.deviceName = v.name;
      row.enterpriseId = v.enterpriseId;
      row.enterpriseName = v.enterpriseName;

      this.disabledIds = [];
      this.deviceServiceForm.deviceServiceList.forEach(d => {
        if (d.deviceId) {
          this.disabledIds.push(d.deviceId);
        }
      });

      this.$set(row, 'spec', v.spec);
    },
    insertEvent () {
      this.deviceServiceForm.deviceServiceList.unshift({
        dataSourceName: '手工新增',
        businessScenarioList: ['MAINTENANCE_SCENARIO'],
        businessScenarioCountList: _.cloneDeep(this.businessTags),
        defaultDevice: {},
      });
    },
    removeSelectEvent ({ $index: index }) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.deviceServiceForm.deviceServiceList.splice(index, 1);
      });
    },
    openTagSelector () {
      if (this.visible) {
        return;
      }

      this.visible = true;
      this.$nextTick(() => {
        this.queryTags();
      });
    },
    queryTags (name = '') {
      this.loading = true;

      SystemSetting.getlabels({
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
        pageFlag: false,
        name,
      })
        .then(res => {
          let labelOptions = res.body?.list || [];

          this.labelOptions = labelOptions.map(v => ({
            labelId: v.id,
            name: v.name,
            remark: v.remark,
          }));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose (index) {
      this.form.labelList.splice(index, 1);
    },
    getRoleLists () {
      SystemSetting.getRoleLists({
        name: '',
        phoneNumber: '',
        pageFlag: false,
        orderByProperty: {
          orderByProperty: 'createTime',
          orderByType: 'DESC',
        },
      }).then(res => {
        this.roleLists = res.body?.list || [];
      });
    },

    getDetail () {
      SystemSetting.getDetailEmploy({
        id: this.id,
        findDepartmentFlag: true,
        findRoleFlag: true,
      }).then(res => {
        const roleIdList = (res.body?.roleRespDTOList || []).map(v => v.id);

        res.body.deviceServiceList = res.body?.deviceServiceList || [];
        res.body.deviceServiceList.forEach(v => {
          this.disabledIds.push(v.deviceId);
          v.businessScenarioList = [];
          v.businessScenarioCountList.forEach(t => {
            if (t.enable) {
              v.businessScenarioList.push(t.businessScenario);
            }
          });

          v.defaultDevice = {
            name: v.deviceName,
            spec: v.spec,
            categoryName: v.deviceClassificationName,
            brandName: v.brandName,
          };

          this.deviceServiceForm.deviceServiceList.push({
            ...v,
            businessScenarioCountList: _.cloneDeep(v.businessScenarioCountList),
          });
        });

        this.form = {
          ...res.body,
          roleIdList,
        };
      });
    },
    handleRoleChange (val) {
      if (val) {
        this.form.roleIdList = [];
      }
    },
    authDeptChange (departList) {
      this.form.authorityDepartmentIdList = departList.map(i => i.id);
    },
    handleParentChanged (item) {
      this.form.departmentName = item.name;
      this.form.departmentId = item.id;
    },
    getbusinessTags () {
      SystemSetting.getBusScenario({ id: '945697336431206410' }).then(res => {
        res.body = res.body || [];

        this.businessTags = res.body.map(v => ({
          businessScenario: v.code,
          count: v.code === 'MAINTENANCE_SCENARIO' ? 0 : null,
          enable: v.code === 'MAINTENANCE_SCENARIO',
        }));
      });
    },

    submit () {
      const apiKey = this.id ? 'updateEmployee' : 'addEmployee';
      SystemSetting[apiKey]({
        ...this.form,
        deviceServiceList: this.deviceServiceForm.deviceServiceList,
      }).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success',
        });

        this.back(true);
      });
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$refs.deviceServiceForm.validate(async valid => {
            if (valid && this.form.adminFlag) {
              // 查询【在职、状态为启用的超级管理员】
              const { body: { list }} = await SystemSetting.getEmployLists({
                findRoleFlag: true,
                pageFlag: false,
                status: 'ON_JOB',
                accountStatus: 'ACTIVE',
                adminFlag: 1,
                orderBy: [
                  {
                    orderByProperty: 'lastUpdateTime',
                    orderByType: 'DESC',
                  },
                ],
              });
              const findCurrent = (list || []).findIndex(item => item.id === this.id);
              const onJobList = (list || []).map(item => `【${item.name}_在职】`) || [];
              const adminListStr = onJobList.join('，');
              if (onJobList.length === 1 && findCurrent !== -1) {
                this.submit();
              } else {
                this.$confirm(`当前超级管理员为${adminListStr}。是否要将【${this.form.name}】设置为超级管理员。设置多个超级管理员易造成权限滥用风险，请确认是否继续设置。`, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }).then(() => {
                  this.submit();
                }).catch(() => {});
              }
            } else if (valid && !this.form.adminFlag) {
              this.submit();
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  created () {
    this.getRoleLists();
    this.getbusinessTags();

    if (this.id) {
      // 编辑
      this.getDetail();
    }
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;

  /deep/ .roleItem .el-form-item__content {
    display: flex;
    .el-radio-group {
      .el-radio {
        margin-top: 10px;
      }
    }
  }

  /deep/ .roleItem .el-form-item__content .el-select {
    flex: 1;
  }

  /deep/ .roleItem {
    .el-form-item__error {
      left: 160px;
    }
  }
}
</style>
